import React from "react";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";

import Layout from "../layouts/layout.js";
import Seo from "../components/seo.js";
import SectionBottomCta from "../components/SectionBottomCta.js";

import { Wrapper, SectionH2, SectionParag, Section1ColWhite } from "../styles/style.js";

const BigButton = styled(Link)`
  display: block;
  background-color: orange;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  padding: 15px 20px;
  border-radius: 3px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;
`;

const SmallButton = styled(BigButton)`
  padding: 10px 15px;
  max-width: 200px;
  background-color: gray;
`;

const FleetSedanPage = () => (
  <Layout>
    <Seo
      title="Lincoln Sedan"
    />

    <Section1ColWhite>
      <Wrapper>
        <StaticImage src="../img/fleet-lincoln-sedan.png" alt="Lincoln Sedan" />
        <SectionH2>Lincoln Sedan</SectionH2>
        <SectionParag>
          We offer beautiful Lincoln L-Series Town Cars for All Events. Our beautiful Town Cars seat up to 4 Passengers and will transport you in style.
        </SectionParag>
        <SectionParag>Capacity: 1 to 3 Passengers</SectionParag>
        <BigButton to="/quote">Request Free Quote</BigButton>
        <SmallButton to="/fleet">View Fleet</SmallButton>
      </Wrapper>
    </Section1ColWhite>

    <SectionBottomCta grayBackground />
  </Layout>
);

export default FleetSedanPage